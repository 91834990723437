import { useEffect, useState } from "react";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import SyncDisableIcon from "src/assets/SyncDisable";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "src/assets/Close";
import CheckIcon from "src/assets/Check";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import {
  getDeskeraRedirectUrl,
  setDisconnectedDeskeraAccount,
  getDeskeraUserInfo,
  setDisconnectedShipStationAccount,
  setShipStationKeys,
  enableShipStationSync,
  disableShipStationSync,
  getShipStationStores,
  saveShipStationStore,
  setShipStationConfig,
  disconnectShipStationStore,
  getShipStationStoreMapping,
  saveShipStationStoreMapping,
  syncShipStationCustomFields,
  getDeskeraTenantDetails,
} from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import { setDeskeraInfo } from "src/redux/actions/auth";
import {
  loadConfig,
  loadConnectedStores,
  setConfig,
  loadAccounts,
} from "src/redux/actions/shipStation";
import SyncIcon from "src/assets/Sync";
import { addToast } from "src/redux/actions/toasts";
import AttributeMapping from "./AttributeMapping";
import AlertsSettings from "./AlertsSettings";
import SyncReportExportSettings from "./SyncReportExportSettings";

function Config() {
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [disconnectWarningShop, setDisconnectWarningShop] = useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncCustomFieldWarning, setSyncCustomFieldWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [disconnectButtonShop, setDisconnectButtonShop] = useState(true);
  const accounts = useSelector((state) => state.shipStation.accounts);
  const storeList = useSelector((state) => state.shipStation.stores);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const shipStationConfig = useSelector((state) => state.shipStation.config);
  const [formErrors, setFormErrors] = useState({});
  const [shipStationKeysFormErrors, setShipStationKeysFormErrors] = useState({});
  const [configOpen, setConfigOpen] = useState(false);
  const [configStoreOpen, setConfigStoreOpen] = useState(false);
  const [configDefaultStoreOpen, setConfigDefaultStoreOpen] = useState(false);
  const [shipStationKeysInfo, setShipStationKeysInfo] = useState({});
  const [shipStationStore, setShipStationStore] = useState(null);
  const [saveAsDefaultStore, setSaveAsDefaultStore] = useState(false);
  const [apiKeysError, setApiKeysError] = useState(false);
  const [saveShopLoading, setSaveShopLoading] = useState(false);
  const [saveStoreLoading, setSaveStoreLoading] = useState(false);
  const [deleteStoreLoading, setDeleteStoreLoading] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [syncCustomFieldLoading, setSyncCustomFieldLoading] = useState(false);
  const [storesLoading, setStoresLoading] = useState(false);
  const [storesOptions, setStoresOptions] = useState([]);
  const [defaultStoreMapping, setDefaultStoreMapping] = useState({});
  const [storeMapping, setStoreMapping] = useState({});
  const [attributeMapping, setAttributeMapping] = useState(false);
  const [alertsPopup, setAlertsPopup] = useState(false);
  const [syncReportExportSettingsPopup, setSyncReportExportSettingsPopup] = useState(false);

  const syncOrderDeletionToShipStation = useSelector(
    (state) => state.shipStation?.config?.data?.syncOrderDeletionToShipStation
  );
  const syncOrderUpdationToShipStation = useSelector(
    (state) => state.shipStation?.config?.data?.syncOrderUpdationToShipStation
  );
  const [saveTransactionSyncLoading, setSaveTransactionSyncLoading] = useState(false);
  const [saveStoreMappingLoading, setSaveStoreMappingLoading] = useState(false);
  const [saveMappingFieldBtn, setSaveMappingFieldBtn] = useState(true);
  const storeMappingOptions = [
    {
      label: "Class",
      value: "CLASS",
    },
    {
      label: "Location",
      value: "LOCATION",
    },
    {
      label: "Create New Custom Field (ShipStation Store)",
      value: "SHIPSTATION_STORE",
    },
  ];
  const shipstationCustomFieldsLabelValue = [
    {
      label: "Custom Field 1",
      value: "CUSTOM_FIELD_1",
    },
    {
      label: "Custom Field 2",
      value: "CUSTOM_FIELD_2",
    },
    {
      label: "Custom Field 3",
      value: "CUSTOM_FIELD_3",
    },
  ];
  const [documentType, setDocumentType] = useState([]);

  async function getStores() {
    try {
      setStoresLoading(true);
      const stores = await getShipStationStores();
      setStoresOptions(stores);
      setStoresLoading(false);
    } catch {
      setError("Failed to load ShipStation stores");
      setStoresLoading(false);
    }
  }

  async function saveStore(defaultStore = false, isUpdate = false) {
    try {
      if (!(shipStationStore?.storeId || shipStationStore?.shipStationStoreId)) {
        return;
      }
      setSaveStoreLoading(true);
      await saveShipStationStore(
        shipStationStore.storeId || shipStationStore?.shipStationStoreId,
        defaultStore
      );
      dispatch(loadConnectedStores());
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: isUpdate
            ? "Store " + shipStationStore?.shipStationStoreName + " set as default."
            : "ShipStation store connected succesfully.",
        })
      );
      setConfigStoreOpen(false);
      setConfigDefaultStoreOpen(false);
      setShipStationStore(null);
      setSaveAsDefaultStore(false);
    } catch (error) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failed",
          message: isUpdate
            ? "Failed to set store " + shipStationStore?.shipStationStoreName + " as default."
            : "Store connected with another Deskera account.",
        })
      );
      setError("Failed to connect ShipStation stores");
    } finally {
      setSaveStoreLoading(false);
    }
  }

  useEffect(() => {
    dispatch(loadAccounts());
    dispatch(loadConfig());
    dispatch(loadConnectedStores());
    getDocumentType();
  }, []);

  useEffect(() => {
    if (!storesOptions?.length && configStoreOpen) {
      getStores();
    }
  }, [configStoreOpen, storesOptions]);

  useEffect(() => {
    getStoreMapping();
  }, []);

  const getStoreMapping = () => {
    getShipStationStoreMapping().then((data) => {
      if (data) {
        const selectedOption = storeMappingOptions.find((option) => option.value === data.value);
        if (selectedOption) {
          setStoreMapping(selectedOption);
          setDefaultStoreMapping(selectedOption);
        }
      }
    });
  };

  const getStoreOptions = () => {
    let filterStoreOptions = storesOptions;
    if (storeList.data?.length) {
      const connectedStoreIds = storeList.data.map((store) => store.shipStationStoreId);
      filterStoreOptions = storesOptions.filter(
        (store) => !connectedStoreIds.includes(store.storeId)
      );
    }
    return filterStoreOptions;
  };

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch (error) {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      const disconnectResp = await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
      dispatch(loadAccounts());
      dispatch(loadConnectedStores());
    } catch (error) {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectShipStationAccount(e, accountId) {
    e.preventDefault();
    setDisconnectWarningShop(false);
    try {
      const disconnectResp = await setDisconnectedShipStationAccount(accountId);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "ShipStation store disconnected succesfully.",
        })
      );
      dispatch(loadAccounts());
      dispatch(loadConnectedStores());
    } catch (error) {
      setError("Failed to disconnect ShipStation store");
    }
  }

  async function deleteShipStationStore(store) {
    try {
      if (!store.shipStationStoreId) {
        return;
      }
      setDeleteStoreLoading(true);
      await disconnectShipStationStore(store.shipStationStoreId);
      dispatch(loadConnectedStores());
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "ShipStation store " + store.shipStationStoreName + " disconnect succesfully.",
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failed",
          message: "Failed to disconnect ShipStation store " + store.shipStationStoreName,
        })
      );
      setError("Failed to disconnect ShipStation store " + store.shipStationStoreName);
    } finally {
      setDeleteStoreLoading(false);
    }
  }

  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    const newConfig = {
      ...shipStationConfig.data,
      syncEnabled: syncStatus,
      disableSyncReason: syncStatus ? "" : shipStationConfig.data.disableSyncReason,
    };
    try {
      if (syncStatus) {
        setSaveSyncLoading(true);
        const enableResp = await enableShipStationSync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been Enabled.",
          })
        );
        setSaveSyncLoading(false);
      } else {
        setSaveSyncLoading(true);
        const disableResp = await disableShipStationSync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been disabled.",
          })
        );
        setSaveSyncLoading(false);
      }
      // const configResp = await setShipStationConfig(newConfig);
      dispatch(setConfig(newConfig));
      setSyncButton(true);
      setSyncWarning(false);
    } catch {
      setError("Failed to update config");
      setSaveSyncLoading(false);
    }
  }

  async function handleCustomFieldSync() {
    try {
      setSyncCustomFieldLoading(true);
      await syncShipStationCustomFields();
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Custom fields created at Deskera.",
        })
      );
    } catch (error) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failed",
          message: "Failed to create custom fields at Deskera.",
        })
      );
      setError("Failed to create custom fields at Deskera.");
    } finally {
      setSyncCustomFieldLoading(false);
      setSyncCustomFieldWarning(false);
    }
  }

  async function handleSaveStoreMapping() {
    if (storeMapping?.value) {
      setSaveStoreMappingLoading(true);
      try {
        await saveShipStationStoreMapping(storeMapping?.value);
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Successfully saved ShipStation store mapping",
          })
        );
        setAttributeMapping(true);
      } catch (err) {
        dispatch(
          addToast({
            type: "danger",
            title: "Failed",
            message: err.message,
          })
        );
        setError("Failed to save ShipStation store mapping");
        getStoreMapping();
      } finally {
        setSaveStoreMappingLoading(false);
        setSaveMappingFieldBtn(true);
      }
    }
  }

  async function getDocumentType() {
    const documentTypes = [];
    documentTypes.push({ value: "SALES_INVOICE", label: "Sales Invoice" });
    try {
      const tenantDetails = await getDeskeraTenantDetails();
      if (tenantDetails !== undefined && tenantDetails.hasOwnProperty("additionalSettings")) {
        const additionalSettings = tenantDetails.additionalSettings;
        if (additionalSettings.hasOwnProperty("ENABLE_SO") && additionalSettings.ENABLE_SO) {
          documentTypes.push({ value: "SALES_ORDER", label: "Sales Order" });
        }
      }
      console.log(tenantDetails);
    } catch (err) {
      return;
    }
    setDocumentType(documentTypes);
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title="Disconnect"
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function connectModalShop() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <p className="text-muted">Link your ShipStation stores.</p>
          <div className="d-flex flex-row-reverse mb-2">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setConfigOpen(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <PlusLogo />
              </span>
              <span>
                {accounts.data.length ? "Add Another Store" : "Connect ShipStation Store"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  function connectedModalShop() {
    return (
      <>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            <p className="text-muted">Your ShipStation account is linked.</p>
            <table className="table m-0 table-no-padding">
              {!disconnectWarningShop && (
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
              )}
              <tbody>
                {accounts.data.map((account, index) => (
                  <div>
                    {!disconnectWarningShop && (
                      <tr>
                        <td className="align-middle" style={{ width: "10%" }}>
                          <p className="m-0">
                            <b>{account.name}</b>
                          </p>
                          <span class="d-inline-block text-truncate">
                            <small>{account.id}</small>
                          </span>
                        </td>
                        <td className="align-middle text-right">
                          {/* <DKButton
                            className="bg-danger text-white"
                            onClick={(e) => setDisconnectWarningShop(true)}
                            title="Disconnect"
                          ></DKButton> */}
                          <button
                            // style={{ marginRight: "10px" }}
                            onClick={(e) => setDisconnectWarningShop(true)}
                            className="btn border-radius-m p-v-s text-white bg-danger"
                          >
                            <span className="svg-icon svg-baseline mr-2 svg-white">
                              <DeleteLogo />
                            </span>
                            <span>Disconnect</span>
                          </button>
                        </td>
                      </tr>
                    )}

                    <CSSTransition
                      in={disconnectWarningShop}
                      timeout={100}
                      classNames="scale-opacity"
                      unmountOnExit
                      onEnter={() => setDisconnectButtonShop(false)}
                      onExited={() => setDisconnectButtonShop(true)}
                    >
                      <Alert className="m-0 flex-fill" type="warning">
                        <p className="m-0">Disconnect ShipStation Store?</p>
                        <div className="d-flex flex-row-reverse">
                          <button
                            className="btn dk-btn font-weight-bold text-muted px-3"
                            onClick={(e) => handleDisconnectShipStationAccount(e, account.id)}
                          >
                            <span className="svg-icon svg-baseline svg-warning">
                              <CheckIcon />
                            </span>
                          </button>
                          <button
                            className="btn dk-btn mr-2 px-3"
                            onClick={(e) => setDisconnectWarningShop(false)}
                          >
                            <span className="svg-icon svg-baseline svg-warning">
                              <CloseIcon />
                            </span>
                          </button>
                        </div>
                      </Alert>
                    </CSSTransition>
                  </div>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }

  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Products and Customers from your ShipStation
          store will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            // <DKButton
            //   className="bg-danger text-white"
            //   title="Disable Sync"
            //   style={{ marginRight: "15px" }}
            //   onClick={(e) => setSyncWarning(true)}
            // ></DKButton>
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => setSyncWarning(true)}
              // style={{ marginRight: "20px" }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncCustomFieldEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          This will create custom fields at Deskera for product and sales orders/invoices (weight,
          dimensions, tracking details etc.).
        </p>
        <div className="d-flex flex-row-reverse">
          {!syncCustomFieldWarning && (
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setSyncCustomFieldWarning(true)}
              disabled={syncCustomFieldLoading}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncIcon />
              </span>
              <span>Create Custom Fields</span>
            </button>
          )}
          <CSSTransition
            in={syncCustomFieldWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Are you sure to continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleCustomFieldSync}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setSyncCustomFieldWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function customFieldMappingModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Select custom field required to sync deskera orders to multiple connected stores.
        </p>
        <Select
          placeholder="Select a field"
          theme={customSelectTheme}
          options={storeMappingOptions}
          menuPlacement="auto"
          getOptionLabel={(option) => option?.label}
          onChange={(e) => {
            setStoreMapping(e);
          }}
          isOptionSelected={(option, selectValue) => option.value === selectValue?.value}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
          value={storeMapping}
          getValue={(option) => option}
        />
        <div className="d-flex flex-row-reverse mt-3">
          {saveMappingFieldBtn && (
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => {
                storeMapping?.value === defaultStoreMapping?.value
                  ? setAttributeMapping(true)
                  : setSaveMappingFieldBtn(false);
              }}
              disabled={saveStoreMappingLoading || !storeMapping?.value}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <FloppyDiskIcon />
              </span>
              <span>
                {storeMapping?.value === defaultStoreMapping?.value
                  ? "Map Stores"
                  : "Save & Map Stores"}
              </span>
            </button>
          )}
          <CSSTransition
            in={!saveMappingFieldBtn}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSaveMappingFieldBtn(false)}
            onExited={() => setSaveMappingFieldBtn(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Are you sure to change ShipStation store mapping field?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleSaveStoreMapping}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setSaveMappingFieldBtn(true)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is Disabled for your account. Orders, Products and Customers from your ShipStation
          store are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {/* <DKButton
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
            className="bg-success text-white"
            title="Enable Sync"
            style={{ marginRight: "15px" }}
          ></DKButton> */}
          <button
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
            className="btn border-radius-m p-v-s text-white bg-success"
            // style={{ marginRight: "20px" }}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  async function handleAddShipStationStore(e) {
    e.preventDefault();
    let isError = false;
    if (!shipStationKeysInfo.shipStationShopName) {
      setShipStationKeysFormErrors({
        ...shipStationKeysFormErrors,
        shopNameError: "Enter a Shop Name",
      });
      return;
    }
    if (!shipStationKeysInfo.shipStationShopUrl) {
      setShipStationKeysFormErrors({
        ...shipStationKeysFormErrors,
        shopUrlError: "Enter shop url (https://example.myshipStation.com)",
      });
      return;
    }
    if (!shipStationKeysInfo.shipStationApiKey) {
      setShipStationKeysFormErrors({
        ...shipStationKeysFormErrors,
        apiKeyError: "API Key is required",
      });
      return;
    }
    if (!shipStationKeysInfo.shipStationApiSecret) {
      setShipStationKeysFormErrors({
        ...shipStationKeysFormErrors,
        apiPasswordError: "API Secret is required",
      });
      return;
    }
    setSaveShopLoading(true);
    try {
      const connResp = await setShipStationKeys(shipStationKeysInfo);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "ShipStation Api Keys saved successfully.",
        })
      );
      dispatch(loadAccounts());
      dispatch(loadConfig());      
      setConfigOpen(false);
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.response.data,
        })
      );
      setError("Failed to add ShipStation Store");
      setApiKeysError(true);
    }
    setSaveShopLoading(false);
  }

  function shipStationConnect() {
    console.log(shipStationKeysFormErrors);
    return (
      <div className="d-flex">
        <div
          className="vw-100 vh-100 d-flex justify-content-center align-items-center"
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div className="card dk-card dk-card-shadow mb-4 d-flex" style={{ width: "70%" }}>
            <div className="card-body d-flex">
              <div className="card-body" width={{ width: "10%" }}>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Store Name</b>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Store name"
                    onChange={(e) => {
                      setShipStationKeysInfo({
                        ...shipStationKeysInfo,
                        shipStationShopName: e.target.value,
                      });
                      setApiKeysError(false);
                      setShipStationKeysFormErrors({
                        ...shipStationKeysFormErrors,
                        shopNameError: "",
                      });
                    }}
                  />
                  {shipStationKeysFormErrors.shopNameError && (
                    <div className="text-danger ">
                      <small>{shipStationKeysFormErrors.shopNameError}</small>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Store URL</b>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="https://example-store.shipStation.com"
                    onChange={(e) => {
                      setShipStationKeysInfo({
                        ...shipStationKeysInfo,
                        shipStationShopUrl: e.target.value,
                      });
                      setApiKeysError(false);
                      setShipStationKeysFormErrors({
                        ...shipStationKeysFormErrors,
                        shopUrlError: "",
                      });
                    }}
                  />
                  {shipStationKeysFormErrors.shopUrlError && (
                    <div className="text-danger">
                      <small>{shipStationKeysFormErrors.shopUrlError}</small>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>ShipStation API Key</b>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter API Key"
                    onChange={(e) => {
                      setShipStationKeysInfo({
                        ...shipStationKeysInfo,
                        shipStationApiKey: e.target.value,
                      });
                      setApiKeysError(false);
                      setShipStationKeysFormErrors({
                        ...shipStationKeysFormErrors,
                        apiKeyError: "",
                      });
                    }}
                  />
                  {shipStationKeysFormErrors.apiKeyError && (
                    <div className="text-danger ">
                      <small>{shipStationKeysFormErrors.apiKeyError}</small>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>ShipStation API Secret</b>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Enter API Secret"
                    onChange={(e) => {
                      setShipStationKeysInfo({
                        ...shipStationKeysInfo,
                        shipStationApiSecret: e.target.value,
                      });
                      setApiKeysError(false);
                      setShipStationKeysFormErrors({
                        ...shipStationKeysFormErrors,
                        apiPasswordError: "",
                      });
                    }}
                  />
                  {shipStationKeysFormErrors.apiPasswordError && (
                    <div className="text-danger ">
                      <small>{shipStationKeysFormErrors.apiPasswordError}</small>
                    </div>
                  )}
                </div>
                {apiKeysError && (
                  <div className="text-danger ">
                    <small>Incorrect ShipStation Info</small>
                  </div>
                )}
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn border-radius-m p-v-s text-white bg-success"
                    onClick={(e) => handleAddShipStationStore(e)}
                    disabled={saveShopLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-white">
                      {saveShopLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                  <button
                    className="btn border-radius-m p-v-s text-white bg-danger"
                    style={{ marginRight: "2%" }}
                    onClick={(e) => {
                      setConfigOpen(false);
                      setApiKeysError(false);
                      setShipStationKeysInfo({});
                    }}
                    disabled={saveShopLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                      <CloseIcon />
                    </span>
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
              <div className="card-body text-muted" style={{ width: "25%" }}>
                To connect ShipStation to Deskera, you will need to provide API key and API password
                which can be generated in ShipStation admin panel. Here is a step-by-step guidance
                that will help you to retrieve ShipStation credentials by your own:
                <ol>
                  <li>Log in your store's admin area and go to Account Settings.</li>
                  <li>Select Account from the left-hand sidebar, then choose API Settings.</li>
                  <li>
                    Click Generate New API Keys if no key and secret are listed yet. API key and API
                    password will appear.
                  </li>
                  <li>
                    If you've already generated your API keys, the existing API keys will be
                    displayed here and the button will say Regenerate API Keys. If you already have
                    API keys, do NOT generate new ones. Instead, copy your existing key and secret
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function shipStationStoreConnect() {
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Connect to a Store</b>
              </div>
              <Select
                placeholder="Select a Store"
                theme={customSelectTheme}
                options={getStoreOptions()}
                isSearchable={true}
                menuPlacement="auto"
                getOptionLabel={(option) => option.storeName}
                onChange={(e) => {
                  setShipStationStore(e);
                }}
                isOptionSelected={(option, selectValue) => option?.storeId === selectValue?.storeId}
                isLoading={storesLoading}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                value={shipStationStore}
                getValue={(option) => option.storeId}
                isDisabled={saveStoreLoading}
              />
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="checkbox"
                id="inlineCheckbox2"
                defaultChecked={storeList?.data?.length === 0 ? true : false}
                onChange={(event) => {
                  setSaveAsDefaultStore((value) => !value);
                }}
                disabled={saveStoreLoading || storeList?.data?.length === 0}
              />
              <label class="form-check-label text-muted" for="inlineCheckbox2">
                Set as default store
              </label>
            </div>
            <div className="d-flex flex-row-reverse">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={() =>
                  saveStore(storeList?.data?.length === 0 ? true : saveAsDefaultStore, false)
                }
                disabled={!shipStationStore || saveStoreLoading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {saveStoreLoading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <PlusLogo />
                  )}
                </span>
                <span>Connect</span>
              </button>
              <button
                className="btn border-radius-m p-v-s mr-2 text-white bg-danger"
                onClick={(e) => {
                  setConfigStoreOpen(false);
                  setShipStationStore(null);
                }}
                disabled={saveStoreLoading}
                // style={{marginRight: "10px"}}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function shipStationDefaultStore() {
    const defaultStore = storeList?.data?.find((store) => store.defaultStore === true);
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Change Default Store</b>
              </div>
              <Select
                placeholder="Select a Store"
                theme={customSelectTheme}
                options={storeList?.data}
                isSearchable={true}
                menuPlacement="auto"
                getOptionLabel={(option) => option.shipStationStoreName}
                onChange={(e) => {
                  setShipStationStore(e);
                }}
                isOptionSelected={(option, selectValue) => option?.id === selectValue?.id}
                isLoading={storesLoading}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                value={shipStationStore || defaultStore}
                isDisabled={saveStoreLoading}
              />
            </div>
            <div className="d-flex flex-row-reverse">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={(e) => saveStore(true, true)}
                disabled={
                  defaultStore?.id === shipStationStore?.id || !shipStationStore || saveStoreLoading
                }
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {saveStoreLoading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <FloppyDiskIcon />
                  )}
                </span>
                Save
              </button>
              <button
                className="btn border-radius-m p-v-s mr-2 text-white bg-danger"
                onClick={(e) => {
                  setConfigDefaultStoreOpen(false);
                  setShipStationStore(null);
                }}
                disabled={saveStoreLoading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                  <CloseIcon />
                </span>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async function saveTransactionSync(e) {
    e.preventDefault();
    setSaveTransactionSyncLoading(true);

    const newConfig = { ...shipStationConfig.data };

    try {
      const resp = await setShipStationConfig(newConfig);
      dispatch(setConfig(newConfig));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Transaction sync config updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveTransactionSyncLoading(false);
  }

  return (
    <div className="p-4 w-auto">
      {configOpen && shipStationConnect()}
      {configStoreOpen && shipStationStoreConnect()}
      {configDefaultStoreOpen && shipStationDefaultStore()}
      {attributeMapping && (
        <AttributeMapping
          storeMapping={storeMapping?.value}
          setCustomerFilterState={setAttributeMapping}
        />
      )}
      {alertsPopup && <AlertsSettings closePopup={() => setAlertsPopup(false)} />}
      {syncReportExportSettingsPopup && (
        <SyncReportExportSettings closePopup={() => setSyncReportExportSettingsPopup(false)} />
      )}
      <h3 className="mb-4 text-bold">Account Setup</h3>

      {shipStationConfig.data?.disableSyncReason && (
        <div className="mb-4">
          <Alert type="warning">{shipStationConfig.data?.disableSyncReason}</Alert>
        </div>
      )}

      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>ShipStation Account</h5>
          </div>
          {deskeraInfo.accountConnected && accounts.data.length
            ? connectedModalShop()
            : connectModalShop()}
          {accounts.data.length > 0 && (
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                <p className="text-muted">Link your ShipStation stores.</p>
                <div className="d-flex flex-row-reverse mb-2">
                  <button
                    className="btn dk-btn font-weight-bold text-success px-3 border border-success"
                    onClick={(e) => setConfigStoreOpen(true)}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      <PlusLogo />
                    </span>
                    <span>
                      {accounts.data.length ? "Add Another Store" : "Connect ShipStation Store"}
                    </span>
                  </button>
                  {storeList?.data?.length > 1 && (
                    <button
                      className="btn dk-btn font-weight-bold text-info px-3 border border-info mr-2"
                      onClick={(e) => setConfigDefaultStoreOpen(true)}
                    >
                      <span>Change Default Store</span>
                    </button>
                  )}
                </div>
                {storeList?.data?.length > 0 && (
                  <table className="table m-0 table-no-padding">
                    <thead>
                      <tr>
                        <th>Connected ShipStation Stores</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {storeList?.data?.map((store, index) => (
                        <tr>
                          <td className="align-middle">
                            <p className="m-0">
                              <b>{store.shipStationStoreName}</b>
                              {store.defaultStore === true && (
                                <span className="ml-2 text-info">
                                  <em>Default</em>
                                </span>
                              )}
                            </p>
                          </td>
                          <td className="align-middle text-right" style={{ width: 180 }}>
                            <button
                              className="btn text-danger px-4 dk-btn border border-danger"
                              onClick={(e) =>
                                window.confirm(
                                  "Please confirm to disconnect store " + store.shipStationStoreName
                                ) && deleteShipStationStore(store)
                              }
                              disabled={deleteStoreLoading || store.defaultStore}
                            >
                              <span className="svg-icon svg-baseline mr-2 svg-danger">
                                <DeleteLogo />
                              </span>
                              <span>Disconnect</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          )}
        </>
      )}

      {/*Custom Field Mapping Modal*/}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && storeList?.data?.length > 1 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>ShipStation Store Mapping</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">{customFieldMappingModal()}</div>
        </>
      )}

      {/*Custom Field Sync Modal*/}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && storeList?.data?.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Sync Custom Fields to Deskera</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {syncCustomFieldEnabledModal()}
          </div>
        </>
      )}

      {/*Data Sync Modal*/}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && storeList?.data?.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {shipStationConfig.data.syncEnabled ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}

      {/* Transaction Sync Modal*/}
      {shipStationConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Transaction Sync</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Sync to ShipStation Settings</b>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox3"
                      defaultChecked={syncOrderDeletionToShipStation}
                      onChange={(event) => {
                        dispatch(
                          setConfig({
                            ...shipStationConfig.data,
                            syncOrderDeletionToShipStation: event.target.checked,
                          })
                        );
                      }}
                    />
                    <label class="form-check-label text-muted" for="inlineCheckbox3">
                      Delete orders/invoices from ShipStation if deleted in Deskera
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="inlineCheckbox4"
                      defaultChecked={syncOrderUpdationToShipStation}
                      onChange={(event) => {
                        dispatch(
                          setConfig({
                            ...shipStationConfig.data,
                            syncOrderUpdationToShipStation: event.target.checked,
                          })
                        );
                      }}
                    />
                    <label class="form-check-label text-muted" for="inlineCheckbox4">
                      Update orders/invoices to ShipStation if updated in Deskera
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Select document type to sync</b>
                  </div>
                  <Select
                    placeholder="Select an Option"
                    theme={customSelectTheme}
                    options={documentType}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch(
                        setConfig({
                          ...shipStationConfig.data,
                          syncBooksDocumentType: e.value,
                        })
                      )
                    }
                    value={documentType.filter(
                      (obj) => obj.value === shipStationConfig.data.syncBooksDocumentType
                    )}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>

                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>Select Custom Field to sync Order Date with Time.</b>
                  </div>
                  <Select
                    placeholder="Select an Option"
                    theme={customSelectTheme}
                    options={shipstationCustomFieldsLabelValue}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch(
                        setConfig({
                          ...shipStationConfig.data,
                          customFieldNo: e.value,
                        })
                      )
                    }
                    value={shipstationCustomFieldsLabelValue.filter(
                      (obj) => String(obj.value) === String(shipStationConfig.data.customFieldNo)
                    )}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </div>
                {
                  <div className="d-flex flex-row-reverse">
                    <button
                      onClick={(e) => saveTransactionSync(e)}
                      disabled={saveTransactionSyncLoading}
                      className="btn border-radius-m p-v-s text-white bg-success"
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-white">
                        {saveTransactionSyncLoading ? (
                          <div
                            class="spinner-border text-white spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <FloppyDiskIcon />
                        )}
                      </span>
                      <span>Save</span>
                    </button>
                  </div>
                }
              </div>
            </div>
          </>
        )}

      {shipStationConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Sync Alerts Settings</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                <p className="text-muted">Configure sync alerts settings</p>
                <div className="d-flex flex-row-reverse">
                  <button
                    onClick={() => setAlertsPopup(true)}
                    className="btn border-radius-m p-v-s text-white bg-success"
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-white">
                      <FloppyDiskIcon />
                    </span>
                    <span>Configure</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

      {shipStationConfig.data.syncEnabled &&
        deskeraInfo.accountConnected &&
        accounts.data.length > 0 && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Daily Sync Report Export Settings</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                <p className="text-muted">Configure daily sync report export settings</p>
                <div className="d-flex flex-row-reverse">
                  <button
                    onClick={() => setSyncReportExportSettingsPopup(true)}
                    className="btn border-radius-m p-v-s text-white bg-success"
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-white">
                      <FloppyDiskIcon />
                    </span>
                    <span>Configure</span>
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
    </div>
  );
}

export default Config;
