import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import {
  setDisconnectedDeskeraAccount,
  getDeskeraRedirectUrl,
  getDeskeraUserInfo,
  setDisconnectedBigcommerceAccount,
  setBigcommerceConfig,
  getDeskeraBooksAccounts,
  getDeskeraBooksWarehouses,
  getDeskeraProductUom,
  getDeskeraBooksTaxes,
  enableBigcommerceSync,
  disableBigcommerceSync,
  getBigcommerceConfig,
  setBigcommerceRefundConfig,
  setBigcommerceDataSyncConfig,
  saveBigcommerceTwoWayConfig,
} from "src/api";
import CheckIcon from "src/assets/Check";
import CloseIcon from "src/assets/Close";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import SyncDisableIcon from "src/assets/SyncDisable";
import SyncIcon from "src/assets/Sync";
import Alert from "src/components/Alert";
import { setDeskeraInfo } from "src/redux/actions/auth";
import { setBigcommerceKeys, getBigcommerceStoreConfig } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import {
  loadAccounts,
  loadConfig,
  // loadPaymentAccountConfig,
  setConfig,
  // setPaymentMethodConfig,
} from "src/redux/actions/bigcommerce";
import Select from "react-select";
import customSelectTheme from "src/utils/selectTheme";
import { showAlert ,DKInput, INPUT_TYPE} from "deskera-ui-library";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import OrderSuffixMapping from "./OrderSuffixMapping";
import StoreWarehouseMapping from "../Bigcommerce/StoreWarehouseMapping";
import StoreAttributeMapping from "../Bigcommerce/StoreAttributeMapping";
import AlertsSettings from "./AlertsSettings";
import SyncReportExportSettings from "./SyncReportExportSettings";
import { Utility } from "src/utils/Utility";
function Config() {
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const accounts = useSelector((state) => state.bigcommerce.accounts);
  const userInfo = useSelector((state) => state.auth.user);
  const config = useSelector((state) => state.bigcommerce.config);
  const [mapDiscountEnabled, setMapDiscountEnabled] = useState(config.data.mapDiscountEnabled);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [disconnectWarningBigcommerce, setDisconnectWarningBigcommerce] = useState(false);
  const [disconnectButtonBigcommerce, setDisconnectButtonBigcommerce] = useState(true);
  const [configOpen, setConfigOpen] = useState(false);
  const [bigcommerceStoreHash, setBigcommerceStoreHash] = useState("");
  const [bigcommerceErrors, setBigcommerceErrors] = useState("");
  const [bigcommerceClientId, setBigcommerceClientId] = useState("");
  const [bigcommerceClientSecret, setBigcommerceClientSecret] = useState("");
  const [bigcommerceAccessToken, setBigcommerceAccessToken] = useState("");
  const [bConnectLoading, setBConnectLoading] = useState(false);
  const [configStoreOptions, setConfigStoreOptions] = useState([]);
  const [configStoreMappings, setConfigStoreMappings] = useState([]);
  const [accountsConfigured, setAccountsConfigured] = useState(false);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [deskeraTaxes, setDeskeraTaxes] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const [deskeraProductUom, setDeskeraProductUom] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [dataSyncFormErrors, setDataSyncFormErrors] = useState({});
  const [refundFormErrors, setRefundFormErrors] = useState({});
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [configDiscountMappings, setConfigDiscountMappings] = useState([]);
  const [bigcommerceStoreState, setBigcommerceStoreState] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [syncWarning, setSyncWarning] = useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [saveSyncSettingsLoading, setSaveSyncSettingsLoading] = useState(false);
  const [saveRefundSettingsLoading, setSaveRefundSettingsLoading] = useState(false);
  const [apiLoadingStates, setApiLoadingStates] = useState({ inventorySetting:false});
  const [storeAttributeMapping, setStoreAttributeMapping] = useState(false);
  const [storeWarehouseMapping, setStoreWarehouseMapping] = useState(false);
  const [orderSuffixMapping, setOrderSuffixMapping] = useState(false);
  const [alertsPopup, setAlertsPopup] = useState(false);
  const [syncReportExportSettingsPopup, setSyncReportExportSettingsPopup] = useState(false);

  const storeMappingOptions = [
    {
      label: "Class",
      value: "CLASS",
    },
    {
      label: "Location",
      value: "LOCATION",
    },
    {
      label: "Create New Custom Field (Bigcommerce Store)",
      value: "BIGCOMMERCE_STORE",
    },
  ];

  const deskeraPaymentMethods = [
    {
      value: "CHEQUE",
      label: "Check",
    },
    {
      value: "BANK_TRANSFER",
      label: "Bank Transfer",
    },
    {
      value: "CARD",
      label: "Card",
    },
    {
      value: "CASH",
      label: "Cash",
    },
    {
      value: "OTHERS",
      label: "Others",
    },
  ];

  const salesReturnTransactionType = [
    { label: "Credit Note", value: "CREDIT_NOTE" },
    { label: "Direct Expense", value: "DIRECT_EXPENSE" },
  ];

  const returnMethods = [
    {
      accessor: "storeCreditReturnMethod",
      name: "Select transactions to be created",
      options: salesReturnTransactionType,
      default: salesReturnTransactionType[0],
    },
    {
      accessor: "storeCreditSalesReturnAccountCode",
      name: "Select Chart Of Account ",
      options: deskeraAccounts,
      required: true,
    },
  ];
  const thirdPartyMethods = [
    {
      accessor: "thirdPartyReturnMethod",
      name: "Select transaction to be created",
      options: salesReturnTransactionType,
      default: salesReturnTransactionType[0],
    },
    {
      accessor: "thirdPartySalesReturnAccountCode",
      name: "Select Chart Of Account ",
      options: deskeraAccounts,
      required: true,
    },
  ];
  const documentTypes = [
    {
      value: "SALES_ORDER",
      label: "Sales Order",
    },
    {
      value: "SALES_INVOICE",
      label: "Sales Invoice",
    },
  ];

  const YesOrNo = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

 
  const deskeraInventoryOptions = [
    { value: "CLASS", label: "Class" },
    { value: "SYNC_TO_ALL_STORES", label: "Sync to all stores" },
    { value: "WAREHOUSE", label: "Warehouse" },
  ];

  const paymentTerms = [
    { label: "Net 0", value: 0 },
    { label: "Net 10", value: 10 },
    { label: "Net 15", value: 15 },
    { label: "Net 30", value: 30 },
    { label: "Net 45", value: 45 },
    { label: "Net 60", value: 60 },
  ];

  const dataSyncSettings = [
    {
      name: "Books Document Type For Bigcommerce Orders",
      accessor: "booksDocumentTypeForBigcommerceOrders",
      placeholder: "Select an Account",
      options: userInfo?.additionalSettings?.ENABLE_SO ? documentTypes : documentTypes.slice(1),
      default: userInfo?.additionalSettings?.ENABLE_SO ? documentTypes[0] : documentTypes[1],
    },
    {
      name: "Enable Sync of Fulfillment from Deskera to Bigcommerce",
      placeholder: "Select an Account",
      accessor: "deskeraToBigcommerceFulfillmentSync",
      options: YesOrNo,
      default: YesOrNo[0],
    },
    {
      name: "Deskera Field to map Bigcommerce Store",
      placeholder: "Select an Account",
      accessor: "deskeraFieldToMapBigcommerceStore",
      options: storeMappingOptions,
      default: storeMappingOptions[0],
    },
    {
      name: "Order Sync Start Date",
      placeholder: "Select an Account",
      accessor: "orderSyncStartDate",
      options: [],
    },
    {
      name: "Use Deskera Sequence Code for Orders",
      accessor: "useDeskeraSequenceCodeForOrders",
      placeholder: "Select an Account",
      options: YesOrNo,
      default: YesOrNo[0],
    },
    {
      name: "Sync orders only if Payment is Available",
      accessor: "isPaymentAvailable",
      placeholder: "Yes/No",
      options: YesOrNo,
      default: YesOrNo[0],
    },
    {
      name: "Payment Term",
      accessor: "paymentTerm",
      placeholder: "Select a payment term",
      options: paymentTerms,
      default: paymentTerms[3],
    },
  ];

  const configAccounts = [
    {
      name: "Contact Payable Account",
      accessor: "contactPayableAccountCode",
      options: deskeraAccounts,
    },
    {
      accessor: "contactReceivableAccountCode",
      name: "Contact Receivable Account",
      options: deskeraAccounts,
    },
    {
      accessor: "paymentDepositAccountCode",
      name: "Payment Deposit Account",
      options: deskeraAccounts,
    },
    {
      accessor: "productSalesAccountCode",
      name: "Product Sales Account",
      options: deskeraAccounts,
    },
    {
      accessor: "productPurchaseAccountCode",
      name: "Product Purchase Account",
      options: deskeraAccounts,
    },
    {
      accessor: "productSalesReturnAccountCode",
      name: "Product Sales Return Account",
      options: deskeraAccounts,
    },
    {
      accessor: "productPurchaseReturnAccountCode",
      name: "Product Purchase Return Account",
      options: deskeraAccounts,
    },
    {
      accessor: "productSalesTaxCode",
      name: "Product Sales Tax Code",
      options: deskeraTaxes,
    },
    {
      accessor: "zeroRatedTaxCode",
      name: "Zero Rated Tax Code",
      options: deskeraTaxes,
    },
    {
      accessor: "productCostOfGoodsSoldAccountCode",
      name: "Product Cost of Goods Sold Account",
      options: deskeraAccounts,
    },
    {
      accessor: "productInventoryAccountCode",
      name: "Product Inventory Account",
      options: deskeraAccounts,
    },
    ,
    {
      accessor: "productStockAdjustmentAccountCode",
      name: "Product Stock Adjustment Account",
      options: deskeraAccounts,
    },
    {
      accessor: "refundPayFromAccountCode",
      name: "Refund Pay From Account",
      options: deskeraAccounts,
    },
    {
      accessor: "refundPaymentMethod",
      name: "Refund Payment Method",
      options: deskeraPaymentMethods,
    },
    {
      accessor: "giftCertificatePaymentAccountCode",
      name: "Gift certificate payment pay from Account",
      options: deskeraAccounts,
    },
    // {
    //   accessor: "refundSalesReturnAccountCode",
    //   name: "Refund Sales Return Account",
    //   options: deskeraAccounts,
    // },
    {
      accessor: "productWarehouseCode",
      name: "Product Default Warehouse",
      options: deskeraWarehouses,
    },
    {
      accessor: "productUom",
      name: "Product UOM",
      options: deskeraProductUom,
    },
    {
      name: "Hide Sync Products",
      accessor: "hideProducts",
      options: YesOrNo,
    },
  ];

  useEffect(() => {
    dispatch(loadAccounts());
    getBigcommerceStore();
    getBooksAccounts();
    getBooksWarehouses();
    getBooksProductUom();
    getBooksTaxes();
    dispatch(loadConfig());
  }, []);

  useEffect(() => {
    handleConfigLoad();
  }, [config]);

  function handleConfigLoad() {
    if (config.data.discountCodes !== undefined) {
      setConfigDiscountMappings(config.data.discountCodes);
    }
    setAccountsConfigured(true);
    if (config.data.bigcommerceStore === undefined) {
      setBigcommerceStoreState(true);
    } else {
      setBigcommerceStoreState(false);
    }

    if (config?.data?.mapDiscountEnabled === undefined) {
      setMapDiscountEnabled(false);
    } else {
      setMapDiscountEnabled(config.data.mapDiscountEnabled);
    }

    configAccounts.forEach((configAccount) => {
      if (!config.data[configAccount.accessor]) {
        setAccountsConfigured(false);
        return;
      }
    });
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      accounts.forEach((account) => {
        accountsOptions.push({ value: account.code, label: account.name });
      });
      setDeskeraAccounts(accountsOptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  async function getBooksProductUom() {
    try {
      const productUom = await getDeskeraProductUom();
      const productUomOptions = [];
      productUom.forEach((uom) => {
        productUomOptions.push({ value: uom.id, label: uom.name });
      });
      setDeskeraProductUom(productUomOptions);
    } catch {
      setError("Failed to load Deskera Books products uom");
    }
  }

  async function getBooksTaxes() {
    try {
      const taxes = await getDeskeraBooksTaxes();
      const taxesOptions = [];
      taxes.forEach((tax) => {
        taxesOptions.push({ value: tax.code, label: tax.name });
      });
      setDeskeraTaxes(taxesOptions);
    } catch {
      setError("Failed to load Deskera Books Taxes");
    }
  }

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            // <DKButton
            //   className="bg-danger px-3 text-white"
            //   onClick={(e) => setDisconnectWarning(true)}
            //   title={"Disconnect"}
            //   style={{ marginRight: "15px" }}
            // ></DKButton>
            <button
              // style={{ marginRight: "20px" }}
              onClick={(e) => setDisconnectWarning(true)}
              className="btn border-radius-m p-v-s text-white bg-danger"
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  async function disconnectBigcommerceStore(e, accountId) {
    await handleDisconnectBigcommerceAccount(e, accountId);
    setDisconnectWarningBigcommerce(false);
  }

  async function handleDisconnectBigcommerceAccount(e, accountId) {
    e.preventDefault();
    try {
      await setDisconnectedBigcommerceAccount(accountId);
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Bigcommerce account");
    }
  }

  function bigcommerceConnectedModal() {
    return (
      <>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            <p className="text-muted">Your Bigcommerce account is linked.</p>
            {accounts.data.length > 0 && (
              <table className="table m-0 table-no-padding">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.data.map((account, index) => (
                    <div>
                      {!disconnectWarningBigcommerce && (
                        <tr style={{ width: "560px" }}>
                          <td className="align-middle" style={{ width: "10%" }}>
                            <p className="m-0">
                              <b>{account.name}</b>
                            </p>
                            <span class="d-inline-block text-truncate" style={{ width: "auto" }}>
                              <small>{account.id}</small>
                            </span>
                          </td>
                          <td className="align-middle text-right">
                            <button
                              // style={{ marginLeft: "10px" }}
                              onClick={(e) => setDisconnectWarningBigcommerce(true)}
                              className="btn border-radius-m p-v-s text-white bg-danger"
                            >
                              <span className="svg-icon svg-baseline mr-2 svg-white">
                                <DeleteLogo />
                              </span>
                              <span>Disconnect</span>
                            </button>
                          </td>
                        </tr>
                      )}

                      <CSSTransition
                        in={disconnectWarningBigcommerce}
                        timeout={100}
                        classNames="scale-opacity"
                        unmountOnExit
                        onEnter={() => setDisconnectButtonBigcommerce(false)}
                        onExited={() => setDisconnectButtonBigcommerce(true)}
                      >
                        <Alert className="m-0 flex-fill" type="warning">
                          <p className="m-0">Disconnect Bigcommerce Store?</p>
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn dk-btn font-weight-bold text-muted px-3"
                              onClick={(e) => {
                                disconnectBigcommerceStore(e, account.id);
                              }}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CheckIcon />
                              </span>
                            </button>
                            <button
                              className="btn dk-btn mr-2 px-3"
                              onClick={(e) => setDisconnectWarningBigcommerce(false)}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CloseIcon />
                              </span>
                            </button>
                          </div>
                        </Alert>
                      </CSSTransition>
                    </div>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </>
    );
  }

  function bigcommerceConnectModal() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          <p className="text-muted">Link your Bigcommerce accounts.</p>
          <div className="d-flex flex-row-reverse mb-2">
            <button
              className="btn border-radius-m p-v-s text-white bg-success"
              onClick={(e) => setConfigOpen(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <PlusLogo />
              </span>
              <span>
                {accounts?.data.length ? "Add Another Store" : "Connect Bigcommerce Store"}
              </span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  async function getBigcommerceStore() {
    try {
      const stores = await getBigcommerceConfig();
      // console.log("bigcommerce stores fetched successfully:", stores);
      const storeOptions = [];
      stores.forEach((store) => {
        if (store.id === 0) {
          storeOptions.push({ value: store.id, label: "All Stores" });
        } else {
          storeOptions.push({ value: store.id, label: store.name });
        }
      });
      setConfigStoreOptions(storeOptions);
      setConfigStoreMappings(stores);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function handleAddBigcommerceAccount(e) {
    console.log("handleAddBigcommerceAccount");
    e.preventDefault();

    if (!bigcommerceStoreHash || bigcommerceStoreHash === "") {
      setBigcommerceErrors({ bigcommerceStoreHash: "Enter Bigcommerce Store Hash" });
      return;
    }
    if (!bigcommerceClientId || bigcommerceClientId === "") {
      setBigcommerceErrors({ bigcommerceClientId: "Enter Bigcommerce Client ID" });
      return;
    }

    if (!bigcommerceClientSecret) {
      setBigcommerceErrors({ bigcommerceClientSecret: "Enter Bigcommerce Client Secret" });
      return;
    }
    if (!bigcommerceAccessToken) {
      setBigcommerceErrors({ bigcommerceAccessToken: "Enter Bigcommerce Access Token" });
      return;
    }

    setBigcommerceErrors(true);
    try {
      const url = new URL(bigcommerceStoreHash);
      const parts = url.pathname.split("/");
      const slug = parts && parts.length > 2 && parts[2];
      const value = {
        bigcommerceStoreHash: slug,
        bigcommerceClientId: bigcommerceClientId,
        bigcommerceClientSecret: bigcommerceClientSecret,
        bigcommerceAccessToken: bigcommerceAccessToken,
      };

      const bigcommerceConnectUrl = await setBigcommerceKeys(value);

      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Bigcommerce account connected successfully.",
        })
      );
      dispatch(loadAccounts());
      getBigcommerceStore();
      setConfigOpen(false);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: err.response.data,
          })
        );
        setBConnectLoading(false);
      } else {
        console.log("error:", err);
        dispatch(
          addToast({
            type: "danger",
            title: "Failure",
            message: "Failed to add Bigcommerce Store",
          })
        );
        setBConnectLoading(false);
      }
    }
    setBConnectLoading(false);
  }

  function bigcommerceConnect() {
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Big Commerce Hash</b>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. https://api.bigcommerce.com/stores/storehash/v3/"
                value={bigcommerceStoreHash}
                onChange={(e) => {
                  setBigcommerceStoreHash(e.target.value);
                }}
                autocomplete="off"
              />
              {bigcommerceErrors.bigcommerceStoreHash && (
                <span className="text-danger">{bigcommerceErrors.bigcommerceStoreHash}</span>
              )}
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Client ID</b>
              </div>
              <input
                // id="site"
                id="bigcommerceClientId"
                type="text"
                className="form-control"
                placeholder="Enter your client id"
                value={bigcommerceClientId}
                onChange={(e) => setBigcommerceClientId(e.target.value)}
                autocomplete="off"
              />
              {bigcommerceErrors.bigcommerceClientId && (
                <span className="text-danger">{bigcommerceErrors.bigcommerceClientId}</span>
              )}
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Client Secret</b>
              </div>
              <input
                id="bigcommerceClientSecret"
                placeholder="Enter your client secret"
                type="text"
                className="form-control"
                value={bigcommerceClientSecret}
                onChange={(e) => setBigcommerceClientSecret(e.target.value)}
                autocomplete="off"
              />
              {bigcommerceErrors.bigcommerceClientSecret && (
                <span className="text-danger">{bigcommerceErrors.bigcommerceClientSecret}</span>
              )}
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Access Token</b>
              </div>
              <input
                placeholder="Enter your access token"
                id="bigcommerceAccessToken"
                type="text"
                className="form-control"
                value={bigcommerceAccessToken}
                onChange={(e) => setBigcommerceAccessToken(e.target.value)}
                autocomplete="off"
              />
              {bigcommerceErrors.bigcommerceAccessToken && (
                <span className="text-danger">{bigcommerceErrors.bigcommerceAccessToken}</span>
              )}
            </div>

            <div className="d-flex flex-row-reverse">
              <button
                className="btn border-radius-m p-v-s text-white bg-success"
                onClick={handleAddBigcommerceAccount}
                disabled={bConnectLoading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {bConnectLoading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <PlusLogo />
                  )}
                </span>
                <span>Connect</span>
              </button>
              <button
                className="btn border-radius-m p-v-s mr-2 text-white bg-danger"
                onClick={(e) => setConfigOpen(false)}
                // style={{marginRight: "10px"}}
              >
                <span className="svg-icon svg-baseline mr-2 svg-disabled svg-white">
                  <CloseIcon />
                </span>
                <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function validateAndSaveAccountConfigs(e) {
    console.log("config:", config);
    let bigcommercePayload = configStoreMappings.filter((store) => {
      return store.id === config?.data?.bigcommerceId;
    });

    console.log("bigcommercePayload:", bigcommercePayload);
    if (bigcommercePayload.length <= 0) {
      console.log("len:", bigcommercePayload.length);
      showAlert("Alert", "Please select the Bigcommerce Store that you want to configure ");
      return;
    } else if (
      (bigcommercePayload.length > 0 &&
        bigcommercePayload[0].id === 0 &&
        config?.data?.bigcommerceStore === undefined) ||
      (config?.data?.bigcommerceStore !== undefined &&
        bigcommercePayload[0].id === 0 &&
        config?.data?.bigcommerceStore[0].id !== 0)
    ) {
      showAlert(
        "Alert",
        "Please note, once you have selected All Stores, this setting cannot be changed. Do you wish to continue?",
        [
          {
            title: "Continue",
            className: "bg-blue mr-r text-white",
            onClick: () => {
              saveAccountConfigs(e);
            },
          },
          {
            title: "Cancel",
            className: "bg-gray text-white",
            onClick: () => {},
          },
        ]
      );
      return;
    } else if (
      bigcommercePayload.length > 0 &&
      bigcommercePayload[0].id !== 0 &&
      config?.data?.bigcommerceStore !== undefined &&
      bigcommercePayload[0].id !== config?.data?.bigcommerceStore[0]?.id
    ) {
      showAlert("Alert", "You cannot change the  already Configured Store !! ");
      return;
    } else {
      saveAccountConfigs(e);
      return;
    }
  }

  function saveSyncSettings(e) {
    e.preventDefault();
    setSaveSyncSettingsLoading(true);
    const payload = {
      syncOrdersIfPaymentAvailable: config?.data?.isPaymentAvailable || false,
      paymentTerm: config?.data?.paymentTerm ?? 30,
      booksDocumentTypeForBigcommerceOrders:
        config?.data?.booksDocumentTypeForBigcommerceOrders ||
        (userInfo?.additionalSettings?.ENABLE_SO ? "SALES_ORDER" : "SALES_INVOICE"),
      orderSyncStartDate: config?.data?.orderSyncStartDate || userInfo?.bookBeginningStartDate,
      deskeraToBigcommerceFulfillmentSync:
        config?.data?.deskeraToBigcommerceFulfillmentSync || false,
      deskeraFieldToMapBigcommerceStore:
        config?.data?.deskeraFieldToMapBigcommerceStore || storeMappingOptions[0]?.value,
      useDeskeraSequenceCodeForOrders: config?.data?.useDeskeraSequenceCodeForOrders || false,
    };
    setBigcommerceDataSyncConfig(payload)
      .then((res) => {
        dispatch(loadConfig());
        setSaveSyncSettingsLoading(false);
      })
      .catch((err) => {
        showAlert("Alert", err ? err?.message : "Unknown error. Please try again");
        setSaveSyncSettingsLoading(false);
      });
  }

  async function saveAccountConfigs(e) {
    let bigcommercePayload = configStoreMappings.filter((store) => {
      return store.id === config.data.bigcommerceId;
    });
    e.preventDefault();
    setSaveConfigLoading(true);
    const payload = {
      ...config.data,
      useBooksTaxRates: false,
      bigcommerceStore: bigcommercePayload,
      orderSyncStartDate:
        config?.data?.orderSyncStartDate === undefined || config?.data?.orderSyncStartDate === null
          ? userInfo.bookBeginningStartDate
          : config.data.orderSyncStartDate,
    };
    try {
      let configResp = await setBigcommerceConfig(payload);
      let bigcommerceStoreId = 0;
      if (configResp && configResp.bigcommerceStore && configResp.bigcommerceStore.length > 0) {
        bigcommerceStoreId = configResp.bigcommerceStore[0].id;
        configResp = { ...configResp, bigcommerceId: bigcommerceStoreId };
      } else if (configResp) {
        configResp = { ...configResp, bigcommerceId: bigcommerceStoreId };
      }
      dispatch(setConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Accounts config updated successfully.",
        })
      );
    } catch (err) {
      setError("Failed to set config", err);
    }
    setSaveConfigLoading(false);
  }



  async function saveInventorySettings() {
    const payload = {
      deskeraField: config["data"]["invDeskeraField"],
      twoWaySync: config["data"]["twoWaySync"],
    };

    try {
      const response = await saveBigcommerceTwoWayConfig(payload);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: response,
        })
      );
      setApiLoadingStates({ ...apiLoadingStates, inventorySetting: true });
    } catch (err) {
      setError("Failed to set config", err);
    
      dispatch(
        addToast({
          type: "danger",
          title:  "Failed",
          message: err?.response?.data ?? "Failed to set Inventory Settings",
        })
      );
    } finally {
      setApiLoadingStates({ ...apiLoadingStates, inventorySetting: false });
    }
  }

  async function saveAccountRefundMethod(e) {
    e.preventDefault();
    const payload = {
      storeCreditTransactionType:
        config?.data?.storeCreditReturnMethod || salesReturnTransactionType[0]?.value,
      storeCreditRefundAccount: config?.data?.storeCreditSalesReturnAccountCode || "",
      thirdPartyTransactionType:
        config?.data?.thirdPartyReturnMethod || salesReturnTransactionType[0]?.value,
      thirdPartyRefundAccount: config?.data?.thirdPartySalesReturnAccountCode || "",
    };
    setRefundFormErrors({});
    if (!payload["storeCreditRefundAccount"] || !payload["thirdPartyRefundAccount"]) {
      setRefundFormErrors({
        storeCreditSalesReturnAccountCode: !payload["storeCreditRefundAccount"]
          ? "Please select account for store credit refund."
          : "",
        thirdPartySalesReturnAccountCode: !payload["thirdPartyRefundAccount"]
          ? "Please select account for third party credit refund."
          : "",
      });
      return;
    }
    setRefundFormErrors({});
    setSaveRefundSettingsLoading(true);
    setBigcommerceRefundConfig(payload)
      .then((res) => {
        dispatch(loadConfig());
        setSaveRefundSettingsLoading(false);
      })
      .catch((err) => {
        showAlert("Alert", err ? err?.message : "Unknown error. Please try again");
        setSaveRefundSettingsLoading(false);
      });
  }

  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Products and Customers from your Bigcommerce
          store will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            <button
              className="btn border-radius-m p-v-s text-white bg-danger"
              onClick={(e) => {
                setSyncWarning(true);
              }}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                <SyncDisableIcon />
              </span>
              <span>Disable Sync</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button className="btn dk-btn mr-2 px-3" onClick={(e) => setSyncWarning(false)}>
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function mapBigcommerceStores() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Map Bigcommorce store with deskera field values.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            onClick={() => setStoreAttributeMapping(true)}
            className="btn border-radius-m p-v-s text-white bg-success"
            disabled={saveConfigLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              {saveConfigLoading ? (
                <div class="spinner-border text-white spinner-border-sm" role="status"></div>
              ) : (
                <FloppyDiskIcon />
              )}
            </span>
            <span>Map Bigcommerce Stores</span>
          </button>
        </div>
      </div>
    );
  }

  function isEmpty(value) {
    return value === undefined || value === null || value === "";
  }

  function dataSyncSettingsModal() {
    return (
      <div className="card w-600 mb-4 dk-card dk-card-shadow">
        <div className="card-body">
          {dataSyncSettings.map((configAccount) => (
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>{configAccount.name}</b>
              </div>
              {configAccount.accessor === "orderSyncStartDate" ? (
                <input
                  style={{
                    backgroundColor: "white",
                    width: "560px",
                    height: "40px",
                    borderRadius: "6px",
                    borderWidth: "2px",
                    borderBlockStyle: "solid",
                  }}
                  type="date"
                  onChange={(date) => {
                    dispatch(
                      setConfig({ ...config.data, [configAccount.accessor]: date.target.value })
                    );
                  }}
                  value={
                    config?.data[configAccount.accessor] === undefined ||
                    config?.data[configAccount.accessor] === null
                      ? userInfo.bookBeginningStartDate
                      : config.data[configAccount.accessor].substring(0, 10)
                  }
                />
              ) : (
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  required={configAccount?.required}
                  options={configAccount.options}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...config.data, [configAccount.accessor]: e.value }))
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  value={configAccount.options.find(
                    ({ value }) =>
                      value ==
                      (!isEmpty(config.data[configAccount.accessor])
                        ? config.data[configAccount.accessor]
                        : configAccount?.default?.value)
                  )}
                />
              )}
              {dataSyncFormErrors[configAccount?.accessor] && (
                <div>{dataSyncFormErrors[configAccount?.accessor]}</div>
              )}
            </div>
          ))}
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              onClick={(e) => saveSyncSettings(e)}
              className="btn border-radius-m p-v-s text-white bg-success"
              disabled={saveSyncSettingsLoading}
            >
              <span className="svg-icon svg-baseline mr-2 svg-white">
                {saveSyncSettingsLoading ? (
                  <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                ) : (
                  <FloppyDiskIcon />
                )}
              </span>
              <span>Save</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  function mapWarehouseStores() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Map Bigcommorce store with deskera warehouses.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            onClick={() => setStoreWarehouseMapping(true)}
            className="btn border-radius-m p-v-s text-white bg-success"
            disabled={saveConfigLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              {saveConfigLoading ? (
                <div class="spinner-border text-white spinner-border-sm" role="status"></div>
              ) : (
                <FloppyDiskIcon />
              )}
            </span>
            <span>Map Warehouses</span>
          </button>
        </div>
      </div>
    );
  }
  function mapOrderSuffix() {
    return (
      <div className="card-body">
        <p className="text-muted">Map Bigcommorce store with deskera order suffix.</p>
        <div className="d-flex flex-row-reverse">
          <button
            onClick={() => setOrderSuffixMapping(true)}
            className="btn border-radius-m p-v-s text-white bg-success"
            disabled={saveConfigLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              {saveConfigLoading ? (
                <div class="spinner-border text-white spinner-border-sm" role="status"></div>
              ) : (
                <FloppyDiskIcon />
              )}
            </span>
            <span>Map Order Suffix</span>
          </button>
        </div>
      </div>
    );
  }

  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    setSaveSyncLoading(true);
    debugger
    try {
      // const configResp = await setMagentoConfig(newConfig);
      if (syncStatus) {
        // if (bigcommerceStoreState) {
        //   showAlert("Alert", "Please Configure Bigcommerce Store, before enabling the data sync");
        //   setSyncButton(true);
        //   setSyncWarning(false);
        //   setSaveSyncLoading(false);
        //   return;
        // }
        await enableBigcommerceSync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been Enabled.",
          })
        );
        dispatch(setConfig({ ...config.data, syncEnabled: syncStatus, disableSyncReason : "" }));
      } else {
        await disableBigcommerceSync();
        dispatch(
          addToast({
            type: "success",
            title: "Success",
            message: "Sync has been disabled.",
          })
        );
        dispatch(setConfig({ ...config.data, syncEnabled: syncStatus }));
      }

      setSyncButton(true);
      setSyncWarning(false);
    } catch (err) {
      setError("Failed to set config");
    }
    setSaveSyncLoading(false);
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is disabled for your account. Orders, Products and Customers from your Bigcommerce
          store are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn border-radius-m p-v-s text-white bg-success"
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-white">
              <SyncIcon />
            </span>
            <span>Enable Sync</span>
          </button>
        </div>
      </div>
    );
  }

  function bigcommerceRefundMethodModal() {
    return (
      <>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            <div className="text-muted mb-2" aria="label">
              <b className="text-muted">Store Credit</b>
            </div>
            {returnMethods.map((returnMethod) => (
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>{returnMethod.name}</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  options={returnMethod.options}
                  required={returnMethod?.required || false}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...config.data, [returnMethod.accessor]: e.value }))
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  value={returnMethod.options.find(
                    ({ value }) =>
                      value ==
                      (!isEmpty(config.data[returnMethod.accessor])
                        ? config.data[returnMethod.accessor]
                        : returnMethod?.default?.value)
                  )}
                />
                {refundFormErrors[returnMethod.accessor] && (
                  <span className="text-danger">{refundFormErrors[returnMethod?.accessor]}</span>
                )}
              </div>
            ))}
            <div className="text-muted mb-2" aria="label">
              <b className="text-muted">Third Party Payment</b>
            </div>
            {thirdPartyMethods.map((thirdMethod) => (
              <div className="form-group">
                <div className="text-muted mb-2" aria="label">
                  <b>{thirdMethod.name}</b>
                </div>
                <Select
                  placeholder="Select an Account"
                  theme={customSelectTheme}
                  required={thirdMethod?.required || false}
                  options={thirdMethod.options}
                  isSearchable={true}
                  menuPlacement="auto"
                  onChange={(e) =>
                    dispatch(setConfig({ ...config.data, [thirdMethod?.accessor]: e.value }))
                  }
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  value={thirdMethod.options.find(
                    ({ value }) =>
                      value ==
                      (!isEmpty(config.data[thirdMethod.accessor])
                        ? config.data[thirdMethod.accessor]
                        : thirdMethod?.default?.value)
                  )}
                />
                {refundFormErrors[thirdMethod.accessor] && (
                  <span className="text-danger">{refundFormErrors[thirdMethod?.accessor]}</span>
                )}
              </div>
            ))}
            <div className="d-flex flex-row-reverse">
              <button
                onClick={saveAccountRefundMethod}
                className="btn border-radius-m p-v-s text-white bg-success"
                disabled={saveRefundSettingsLoading}
              >
                <span className="svg-icon svg-baseline mr-2 svg-white">
                  {saveRefundSettingsLoading ? (
                    <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                  ) : (
                    <FloppyDiskIcon />
                  )}
                </span>
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="p-4 w-auto">
      {configOpen && bigcommerceConnect()}
      {storeAttributeMapping && (
        <StoreAttributeMapping
          storeMapping={config?.data?.deskeraFieldToMapBigcommerceStore}
          setCustomerFilterState={setStoreAttributeMapping}
        />
      )}
      {storeWarehouseMapping && (
        <StoreWarehouseMapping
          storeMapping={config?.data?.deskeraFieldToMapBigcommerceStore}
          setCustomerFilterState={setStoreWarehouseMapping}
        />
      )}
      {orderSuffixMapping && (
        <OrderSuffixMapping
          storeMapping={config?.data?.deskeraFieldToMapBigcommerceStore}
          setCustomerFilterState={setOrderSuffixMapping}
        />
      )}
      {alertsPopup && <AlertsSettings closePopup={() => setAlertsPopup(false)} />}
      {syncReportExportSettingsPopup && (
        <SyncReportExportSettings closePopup={() => setSyncReportExportSettingsPopup(false)} />
      )}

      <h3 className="mb-4">Account Setup</h3>

      {config?.data?.disableSyncReason && (
        <div className="mb-4">
          <Alert type="warning">{config?.data?.disableSyncReason}</Alert>
        </div>
      )}

      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>

      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Bigcommerce Stores</h5>
          </div>
          {/* {accounts.data.length ? bigcommerceConnectedModal() : bigcommerceConnectModal()} */}
          {bigcommerceConnectedModal()}
        </>
      )}
      {bigcommerceConnectModal()}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Books Accounts</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!accountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Bigcommerce products can be synced with Deskera
                  Books.
                </Alert>
              )}
              {configAccounts.map((configAccount) => (
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>{configAccount.name}</b>
                  </div>
                  {configAccount.accessor === "orderSyncStartDate" ? (
                    <input
                      style={{
                        backgroundColor: "white",
                        width: "560px",
                        height: "40px",
                        borderRadius: "6px",
                        borderWidth: "2px",
                        borderBlockStyle: "solid",
                      }}
                      type="date"
                      onChange={(date) => {
                        dispatch(
                          setConfig({ ...config.data, [configAccount.accessor]: date.target.value })
                        );
                      }}
                      value={
                        config?.data[configAccount.accessor] === undefined ||
                        config?.data[configAccount.accessor] === null
                          ? userInfo.bookBeginningStartDate
                          : config.data[configAccount.accessor].substring(0, 10)
                      }
                    />
                  ) : (
                    <Select
                      placeholder="Select an Account"
                      theme={customSelectTheme}
                      options={configAccount.options}
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(e) =>
                        dispatch(setConfig({ ...config.data, [configAccount.accessor]: e.value }))
                      }
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      value={configAccount.options.find(
                        ({ value }) => value == config.data[configAccount.accessor]
                      )}
                    />
                  )}
                  {formErrors[configAccount.accessor] && (
                    <div>{formErrors[configAccount.accessor]}</div>
                  )}
                </div>
              ))}

              <div className="d-flex flex-row-reverse">
                <button
                  onClick={saveAccountConfigs}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Refund Methods</h5>
          </div>
          {bigcommerceRefundMethodModal()}
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync Settings</h5>
          </div>
          {dataSyncSettingsModal()}
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Data Sync</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {config.data.syncEnabled === true ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}

      {deskeraInfo.accountConnected &&
        accounts.data.length > 0 &&
        config?.data?.deskeraFieldToMapBigcommerceStore && (
          <>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Map Bigcommerce Stores</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">{mapBigcommerceStores()}</div>
          </>
        )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Map Bigcommerce Stores With Deskera Warehouses</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">{mapWarehouseStores()}</div>
        </>
      )}
      {config?.data?.useDeskeraSequenceCodeForOrders == false && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Map Bigcommerce Stores With Deskera Order Suffix</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">{mapOrderSuffix()}</div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Sync Alerts Settings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <p className="text-muted">Configure sync alerts settings</p>
              <div className="d-flex flex-row-reverse">
                <button
                  onClick={() => setAlertsPopup(true)}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Configure</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Inventory Settings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <div className="form-group gap-2">
                <div class="form-check form-check-inline my-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="transaction_sync_toggle"
                    defaultChecked={config["data"]["twoWaySync"]}
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(setConfig({ ...config.data, twoWaySync: true }));
                      } else {
                        dispatch(setConfig({ ...config.data, twoWaySync: false }));
                      }
                    }}
                  />
                  <label class="form-check-label text-muted" for="transaction_sync_toggle">
                    Enable 2-way Sync of Inventory
                  </label>
                </div>

                <div className="text-muted my-2" aria="label">
                  <b>{`Inventory Deskera Field`}</b>
                </div>
                <Select
                  placeholder="Select an Deskera"
                  theme={customSelectTheme}
                  required={false}
                  options={deskeraInventoryOptions}
                  isSearchable={true}
                  className="mb-2"
                  menuPlacement="auto"
                  onChange={(e) => {
                    dispatch(setConfig({ ...config.data, invDeskeraField: e.value }));
                  }}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  value={deskeraInventoryOptions.find(
                    ({ value }) => String(value) === String(config.data?.invDeskeraField)
                  )}
                />
              </div>

              <div className="d-flex flex-row-reverse">
                <button
                  onClick={saveInventorySettings}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={apiLoadingStates.inventorySetting}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Daily Sync Report Export Settings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              <p className="text-muted">Configure daily sync report export settings</p>
              <div className="d-flex flex-row-reverse">
                <button
                  onClick={() => setSyncReportExportSettingsPopup(true)}
                  className="btn border-radius-m p-v-s text-white bg-success"
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-white">
                    {saveConfigLoading ? (
                      <div class="spinner-border text-white spinner-border-sm" role="status"></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Configure</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Config;
